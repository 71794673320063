/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import t from "../../../Color.js";
import r from "../../../core/Accessor.js";
import { property as i } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as s } from "../../../core/accessorSupport/decorators/subclass.js";
let l = class extends r {
  constructor() {
    super(...arguments), this.color = new t([0, 255, 255]), this.haloOpacity = 1, this.fillOpacity = .25, this.multiHighlightEnabled = !1;
  }
  equals(o) {
    return this.color.equals(o.color) && (this.haloColor || this.color).equals(o.haloColor || o.color) && this.haloOpacity === o.haloOpacity && this.fillOpacity === o.fillOpacity && this.multiHighlightEnabled === o.multiHighlightEnabled;
  }
};
o([i({
  type: t
})], l.prototype, "color", void 0), o([i({
  type: t
})], l.prototype, "haloColor", void 0), o([i()], l.prototype, "haloOpacity", void 0), o([i()], l.prototype, "fillOpacity", void 0), o([i()], l.prototype, "multiHighlightEnabled", void 0), l = o([s("esri.views.2d.support.HighlightOptions")], l);
const p = l;
export { p as default };